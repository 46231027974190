export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
    
      <div className='container'>
        <div className='section-title'>
          <h2>ACHIEVEMENTS </h2>
          <span>
            Technology Reach and Touches your business.
          </span>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-6'>
                                                
                  <div className='services-text list-style'>
                    <ul>
                     {d.text
                      ? d.text.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}
                     </ul>
                    </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
